import { ChatPage, LoginPage, WelcomePage } from "../pages";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { AuthProvider } from "../context";
import { InstanceProvider } from "../context/instance";
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<LoginPage />} />
      <Route path="/chat" element={<ChatPage />} />
      <Route path="/welcome" element={<WelcomePage />} />
    </Route>
  )
);
export default function IntegraRouter() {
  return (
    <AuthProvider>
      <InstanceProvider>
        <RouterProvider router={router} />
      </InstanceProvider>
    </AuthProvider>
  );
}
