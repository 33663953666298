import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

// Crea una instancia de Axios
export const api = axios.create({
  baseURL: API_URL,
});

// Agrega un interceptor para adjuntar el token a cada solicitud
api.interceptors.request.use(
  async (config) => {
    // Obtén el token de almacenamiento seguro (puede ser AsyncStorage, SecureStore, etc.)
    const token = sessionStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
