import { useEffect } from "react";
import { useAuthContext } from "../context";
import { useNavigate } from "react-router-dom";
import { AdminDashboard, ClientDashboard } from "../components/dashboards";

const Welcome = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [navigate, user]);
  return user?.role === "cyt" ? <AdminDashboard /> : <ClientDashboard />;
};

export default Welcome;
