import { AxiosResponse } from "axios";
import { api } from "../utils/api";
import {
  AddInstancePayload,
  AddInstanceResponse,
  MetaAccountsResponse,
} from "../interfaces";
import { createContext, useContext, useState, ReactNode } from "react";
import { useAuthContext } from "./auth";

export interface InstanceContextType {
  instances: AddInstanceResponse[];
  addInstance: (
    payload: AddInstancePayload
  ) => Promise<AddInstanceResponse | null>;
  updateInstance: (
    payload: AddInstancePayload,
    id: string
  ) => Promise<AddInstanceResponse | null>;
  getUserInstances: (ownerId: string) => Promise<AddInstanceResponse[] | null>;
  getMetaInstances: () => void;
  validateInstance: (metaId: string) => Promise<AddInstanceResponse | null>;
}

const InstanceContext = createContext<InstanceContextType | undefined>(
  undefined
);

export const InstanceProvider = ({ children }: { children: ReactNode }) => {
  const [instances, setInstances] = useState<AddInstanceResponse[]>([]);
  const { user } = useAuthContext();

  const addInstance = async (
    payload: AddInstancePayload
  ): Promise<AddInstanceResponse | null> => {
    try {
      const response: AxiosResponse<AddInstanceResponse> = await api.post(
        "/instances",
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const updateInstance = async (
    payload: AddInstancePayload,
    id: string
  ): Promise<AddInstanceResponse | null> => {
    try {
      const response: AxiosResponse<AddInstanceResponse> = await api.patch(
        `/Instances/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getUserInstances = async (
    ownerId: string
  ): Promise<AddInstanceResponse[] | null> => {
    try {
      const response: AxiosResponse<AddInstanceResponse[]> = await api.get(
        `/instances/owner/${ownerId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setInstances([...response.data]);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const validateInstance = async (
    metaId: string
  ): Promise<AddInstanceResponse | null> => {
    try {
      const response: AxiosResponse<AddInstanceResponse> = await api.get(
        `/instances/validate-with-meta-id/${metaId}`
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  };
  const getMetaInstances = async () => {
    try {
      window.FB.api(
        `/me/accounts`,
        {
          access_token: user?.token,
          fields:
            "access_token,instagram_business_account{id,username},name,picture,username",
        },
        async (response: MetaAccountsResponse) => {
          const updatePromises = response.data.map(async (account) => {
            const validFacebookInstance = await validateInstance(account.id);
            let payload: AddInstancePayload = {
              name: account.name,
              meta: {
                token: account.access_token,
                providerId: account.id,
                type: "facebook",
              },
              owner: user?.id ?? "-",
            };
            if (
              validFacebookInstance !== null &&
              validFacebookInstance !== undefined
            ) {
              updateInstance(payload, validFacebookInstance._id);
            } else {
              addInstance(payload);
            }
            // Verifica si el account tiene una cuenta de Instagram y asigna el resultado
            if (account.instagram_business_account) {
              const validInstagraminstance = await validateInstance(
                account.instagram_business_account.id
              );
              let payload: AddInstancePayload = {
                name: account.name,
                meta: {
                  token: account.access_token,
                  providerId: account.instagram_business_account.id,
                  type: "instagram",
                },
                owner: user?.id ?? "-",
              };
              if (
                validInstagraminstance !== null &&
                validInstagraminstance !== undefined
              ) {
                updateInstance(payload, validInstagraminstance._id);
              } else {
                addInstance(payload);
              }
            }
            // Retorna el account modificado para incluirlo en items
            return account;
          });
          await Promise.all(updatePromises);
        }
      );
    } catch {
      setInstances([]);
      console.log("No se pudo obtener la lista de instancias");
    }
  };

  const contextValue: InstanceContextType = {
    instances,
    addInstance,
    updateInstance,
    getUserInstances,
    getMetaInstances,
    validateInstance,
  };

  return (
    <InstanceContext.Provider value={contextValue}>
      {children}
    </InstanceContext.Provider>
  );
};

export const useInstanceContext = (): InstanceContextType => {
  const context = useContext(InstanceContext);

  if (!context) {
    throw new Error(
      "useInstanceContext debe usarse dentro de un InstanceProvider"
    );
  }

  return context;
};
