import "./App.css";

import { default as IntegraRouter } from "./routes";

// Declara la interfaz para la propiedad 'FB' en 'window'
interface FB {
  init: (params: {
    appId: string;
    autoLogAppEvents: boolean;
    cookie: boolean;
    xfbml: boolean;
    version: string;
  }) => void;
  getLoginStatus: (callback: (response: any) => void) => void;
  login: (
    callback: (response: any) => void,
    options?: { scope: string }
  ) => void;
  logout: (callback?: () => void) => void;
  api: (
    path: string,
    params: Record<string, any>,
    callback: (response: any) => void
  ) => void;
}

// Agregar la declaración de la interfaz a 'Window'
declare global {
  interface Window {
    FB: FB;
    fbAsyncInit: () => void;
  }
}
function App() {
  return <IntegraRouter />;
}

export default App;
